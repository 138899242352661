<template>
    <div class="commision" ref="gwn">
        <div class="section-date">
          <div>选择月份: </div>
          <div class="section-date-start" @click="onDatePickerOpen('startDate')">
            {{ startDate }}
          </div>
        </div>
        <div class="commision-warp">
            <div style="flex:1">
                <h1>{{today_commision}}</h1>
                <div class="commision-warp-label">今日业绩</div>
            </div>
            <div class="commision-circle">
                <EnvProgress
                        :radius="radius"
                        :value="currentShowItem.value"
                        :leftValue="currentShowItem.minValue"
                        :rightValue="currentShowItem.maxValue"
                        activeColor="#00cca2"
                        defaultColor="#999"
                        class="commision-envProgress">
                </EnvProgress>
                <div class="commision-circle-center">
                    <div>
                        <h1 style="color:#00cca2">{{month_total_commision}}</h1>
                        <div style="text-align:center">业绩完成</div>
                    </div>
                </div>
            </div>
            <div style="flex:1">
                <h1>{{month_commision_rank}}</h1>
                <div class="commision-warp-label">业绩排名</div>
            </div>
        </div>

        <!-- <van-grid :column-num="2" :gutter="10">
          <van-grid-item v-for="value in 4" :key="value" icon="photo-o" text="文字" />
        </van-grid> -->

        <div v-if="!loading" class="commision-table">

            <!-- <van-cell-group>
                <van-cell>
                    <template #title>
                        <span>23123123213</span>
                    </template>
                    <template #label>
                        <div class="custom-title">交易订单：3123132131321</div>
                    </template>
                </van-cell>
            </van-cell-group> -->

            <ve-histogram 
                :data="chartData" 
                height="200px"
                :legend="{show:false}"
                judge-width
                :grid="{right: '0%',left:'0%',top:'10%',bottom:'0%'}"
                :mark-line="markLine"
                :settings="{label: { show: true, position: 'top'},yAxisType: ['KMB', 'KMB']}" 
                :extend="{
                    yAxis:{
                        splitLine: {
                        lineStyle: {
                            type: 'dotted'
                        }
                        }
                    },
                    series:{
                        type:'bar',
                        barMaxWidth: 5,
                        itemStyle:{
                            normal:{
                                color: function(params) {
                                    return {
                                        type: 'linear',
                                        x:1,
                                        y:1,
                                        x2:0,
                                        y2:0,
                                        colorStops: [{
                                            offset: 0,
                                            color: '#effffb'
                                            
                                        }, {
                                            offset: 1,                    
                                            color: '#23d9ae'
                                        }],
                                    }
                                },
                                barBorderRadius:[5,5,0,0]
                            }
                        }
                },
                dataZoom:[{type:'inside'}]}" ></ve-histogram>
        </div>
        <!-- <div v-if="!loading" class="commision-table">
            <ve-table
                :columns="columns" 
                :table-data="month_commision_list"
                style="width:100%" 
                :scroll-width="400"
            />
            <div v-show="month_commision_list.length == 0 && !loading" class="v-table-empty-data">暂无数据</div>
        </div> -->
        <van-popup v-model="dateShowPicker" position="bottom">
            <van-datetime-picker
                v-model="currentTime"
                type="year-month"
                :min-date="minDate"
                :max-date="maxDate"
                :formatter="formatter"
                @confirm="onDatePickerConfirm"
                @cancel="onDatePickerClose"
            />
        </van-popup>
        <loadings :loading='loading' />
    </div>
</template>

<script>
    import { toThousands } from '@/common/util'
    import EnvProgress from './envProgress'
    import VeHistogram from 'v-charts/lib/histogram.common'
    import Loadings from '@/components/loading'
    import { Field, DatetimePicker, Picker, Grid, GridItem,Cell, CellGroup} from 'vant'
    import dayjs from 'dayjs'
    import gwm from 'gwm'
    export default {
        name:'commision',
        data() {
            this.markLine = {
                symbol: ['none', 'none'], //去掉箭头
                data: [
                    {
                    name: '平均线',
                    type: 'average'
                    }
                ]
            }
            return {
                loading:false,
                radius: 110,
                currentShowItem:{
                    value:0,
                    maxValue:'0',
                    minValue:'0'
                },
                today_commision:0,
                month_commision_rank:0,
                month_total_commision:0,
                dateType: 'startDate', // startDate,endDate
                startDate: dayjs().format('YYYY-MM'),
                endDate: dayjs().format('YYYY-MM-DD'),
                currentTime:'',
                currentStart: dayjs().startOf('month').$d,
                currentEnd: new Date(),
                minDate: new Date(2010, 0, 1),
                maxDate: new Date(),
                dateShowPicker: false,
                chartData:{},
                month_commision_list:[],
                columns: [
                    {field: 'studio_name', key:'studio_name', title: '校区名称', width: 110,fixed: "left"},
                    {field: 'channel_name', key:'channel_name', title: '来源渠道', width: 110,},
                    {field: 'commision_type', key:'commision_type', title: '合同类型', width: 90,},
                    {field: 'income_pirce', key:'income_pirce', title: '签约金额', width: 90,},
                    {field: 'commision_price', key:'commision_price', title: '绩效提成', width: 90,},
                    {field: 'created_at', key:'created_at', title: '结算时间', width: 150,},
                ]
            }
        },
        components: {
            EnvProgress,
            loadings:Loadings,
            [Grid.name]:Grid,
            [GridItem.name]:GridItem,
            [VeHistogram.name]: VeHistogram,
            [Field.name]: Field,
            [Cell.name]: Cell,
            [CellGroup.name]: CellGroup,
            [DatetimePicker.name]: DatetimePicker,
            [Picker.name]: Picker,
        },
        created () {
            this.getCommision()
            this.getEmployee()
        },
        methods: {
            getEmployee(){
                this.$api.employee_index().then(res => {
                    localStorage.setItem('employee_watermark',res.data.employee.employee_watermark)
                    this.$nextTick(()=>{
                    gwm.creation({
                        container:this.$refs.gwn,
                        txt:localStorage.getItem('employee_watermark'),
                        mode: 'svg',
                        watch: false,
                        fontSize: 11,
                        height:80,
                        x: 20,
                        y: 30,
                        color: '#bbbfca',
                        font: 'sans-serif',
                        alpha: 0.2,
                        angle: -15
                    })
                    })
                })
            },
            getCommision(){
                this.loading = true
                let obj = {
                    start_date:dayjs(this.currentStart).format("YYYY-MM-DD"),
                    end_date:dayjs(this.currentEnd).format("YYYY-MM-DD")
                }
                this.$api.crm_commision(obj).then(res=>{
                    this.today_commision = res.data.today_commision
                    this.month_commision_rank = res.data.month_commision_rank
                    this.month_total_commision = res.data.month_total_commision
                    this.currentShowItem.maxValue = toThousands(res.data.month_goal)
                    this.currentShowItem.minValue = toThousands(res.data.month_achievement)
                    this.chartData = res.data.month_commision_chart
                    this.month_commision_list = res.data.month_commision_list
                    let value = res.data.month_achievement/res.data.month_goal
                    this.currentShowItem.value = value.toFixed(2)*100
                    this.loading = false
                })
            },
            formatter(type, val) {
                if (type === 'year') {
                    return `${val}年`
                } else if (type === 'month') {
                    return `${val}月`
                } else {
                    return `${val}日`
                }
            },
            onDatePickerOpen(type) {
                console.log(type)
                if(type == 'startDate'){
                    this.currentTime = this.currentStart
                } else{
                    this.currentTime = this.currentEnd
                }
                this.dateType = type
                this.dateShowPicker = true
            },
            onDatePickerClose() {
                this.dateShowPicker = false
            },
            onDatePickerConfirm(time) {
                this[this.dateType] = dayjs(time).format('YYYY-MM')
                if(dayjs(time).format('YYYY-MM') == dayjs().format('YYYY-MM')){
                    this.currentStart = dayjs(time).startOf('month').$d
                    this.currentEnd = dayjs().$d
                }else{
                    this.currentStart = dayjs(time).startOf('month').$d
                    this.currentEnd = dayjs(time).endOf('month').$d
                }
                this.onDatePickerClose()
                this.getCommision()
            },
        },
    }
</script>

<style lang="less" scoped>
    .commision{
        min-height: 100vh;
        background: #fff;
        &-envProgress{
            width: 180px;
            height: 180px;
        } 
        &-warp{
            display: flex;
            align-items: center;
            justify-content: center;
            h1{
                text-align: center;
                font-size: 20px;
            }
            &-label{
                font-size: 12px;
                text-align: center;
            }
        }
        &-circle{
            position: relative;
            &-center{
                position: absolute;
                top: 0;
                right: 0;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        &-table{
            padding: 5px 15px;
        }
        .section{
            background: #fff;
            &-date{
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-left: 10px;
                padding: 10px 0;
                position: relative;
                z-index: 100;
            &-start{
                margin-left: 10px;
                box-sizing: border-box;
                width: 100px;
                height: 30px;
                border-radius: 4px;
                padding: 4px 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #666;
                font-size: 12px;
                border: 1px solid #eee;
            }
            &-end{
                margin-left: 10px;
                box-sizing: border-box;
                width: 100px;
                height: 30px;
                border-radius: 4px;
                padding: 4px 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #666;
                font-size: 12px;
                border: 1px solid #eee;
            }
            }
        }
    }
</style>
<style lang="less">
  .commision{
    .v-table-views{
      background-color: rgba(0,0,0,0) !important;
    }
    .v-table-header{
      background-color: rgba(0,0,0,0) !important;
    }
  }
  .v-table-empty-data{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
    width: 100%;
    color: #666;
    font-size: 14px;
    border: 1px solid #eee;
    border-top: 0;
  }
</style>